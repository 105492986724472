import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { FormRegister } from "../../redux/action";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { v4 as uuidv4 } from "uuid"; // Importar uuid si estás usando esta librería

const RegisterSchool = () => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const autocompleteRef = useRef(null); // Referencia para el Autocomplete
  const [selectedImageGallery, setSelectedImageGallery] = useState([]);
  const [previewImageGallery, setPreviewImageGallery] = useState(null);
  const handleImageChangeGallery = (e) => {
    const files = Array.from(e.target.files); // Para manejar múltiples archivos
    const newImages = files.map((file) => ({
      id: uuidv4(),
      foto: URL.createObjectURL(file), // Previsualización
      file, // Archivo original para guardar
    }));

    // Actualizar el estado con las imágenes nuevas
    setFormData((prevData) => ({
      ...prevData,
      galeria: [...prevData.galeria, ...newImages], // Guardar objetos con previsualización y archivo original
    }));
  };

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phone: "",
    email: "",
    year_of_operation: "",
    province: "",
    sic: "",
    urlYoutube: "",

    postgrado1: "",
    postgrado2: "",
    beca1: "",
    beca2: "",

    licenciatura1: "",
    licenciatura2: "",
    historia: "",

    alumnos: [],
    alumnos_mixta: [],

    egresados: [],

    doctoresJubilados: [],
    doctoresCandidatos: [],
    profesores: [],
    edad_profesores: [],

    profesoresMaestrias: [],
    profesoresConDoctorados: [],
    plantel1: null,
    plantel2: null,
    plantel3: null,
    image: null,
    profesoresTemporales: [],
    edadesprofesoresTemporales: [],
    matriculaDocentes: [],
    matriculaDocentesEspecialidad: [],

    profesoresTemporalesMaestria: [],
    profesoresTemporalesDoctorado: [],
    cuerposAcademicos: [],
    ofertaAcademicos: [],
    galeria: [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSuccess(true);

    try {
      const date = new Date(formData.year_of_operation);
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        "0"
      )}/${String(date.getMonth() + 1).padStart(2, "0")}/${date.getFullYear()}`;

      const data = new FormData();
      data.append("name", formData.name);
      data.append("address", formData.address);
      data.append("phone", formData.phone);
      data.append("email", formData.email);
      data.append("year_of_operation", formattedDate);
      data.append("province", formData.province);
      data.append("sic", formData.sic);
      data.append("urlYoutube", formData.urlYoutube);

      data.append("postgrado1", formData.postgrado1);
      data.append("postgrado2", formData.postgrado2);
      data.append("licenciatura1", formData.licenciatura1);
      data.append("licenciatura2", formData.licenciatura2);
      data.append("historia", formData.historia);

      data.append("beca1", formData.beca1);
      data.append("beca2", formData.beca2);
      if (formData.alumnos) {
        data.append("alumnos", JSON.stringify(formData.alumnos));
      }
      if (formData.alumnos_mixta) {
        data.append("alumnos_mixta", JSON.stringify(formData.alumnos_mixta));
      }
      if (formData.egresados) {
        data.append("egresados", JSON.stringify(formData.egresados));
      }

      if (formData.doctoresJubilados) {
        data.append(
          "doctoresJubilados",
          JSON.stringify(formData.doctoresJubilados)
        );
      }
      if (formData.doctoresCandidatos) {
        data.append(
          "doctoresCandidatos",
          JSON.stringify(formData.doctoresCandidatos)
        );
      }
      if (formData.profesores) {
        data.append("profesores", JSON.stringify(formData.profesores));
      }
      if (formData.edad_profesores) {
        data.append(
          "edad_profesores",
          JSON.stringify(formData.edad_profesores)
        );
      }
      if (formData.profesoresMaestrias) {
        data.append(
          "profesoresMaestrias",
          JSON.stringify(formData.profesoresMaestrias)
        );
      }
      if (formData.profesoresConDoctorados) {
        data.append(
          "profesoresConDoctorados",
          JSON.stringify(formData.profesoresConDoctorados)
        );
      }

      if (formData.matriculaDocentes) {
        data.append(
          "matriculaDocentes",
          JSON.stringify(formData.matriculaDocentes)
        );
      }
      if (formData.matriculaDocentesEspecialidad) {
        data.append(
          "matriculaDocentesEspecialidad",
          JSON.stringify(formData.matriculaDocentesEspecialidad)
        );
      }

      if (formData.cuerposAcademicos) {
        data.append(
          "cuerposAcademicos",
          JSON.stringify(formData.cuerposAcademicos)
        );
      }

      if (formData.ofertaAcademicos) {
        data.append(
          "ofertaAcademicos",
          JSON.stringify(formData.ofertaAcademicos)
        );
      }

      if (formData.image) {
        data.append("image", formData.image);
      }
      if (formData.galeria && Array.isArray(formData.galeria)) {
        // Agregar todos los archivos de la galería como parte de un array.
        formData.galeria.forEach((row) => {
          data.append("galeria", row.file); // Enviar solo el archivo original
        });
      }

      if (formData.plantel1) {
        data.append("plantel1", formData.plantel1);
      }
      if (formData.plantel2) {
        data.append("plantel2", formData.plantel2);
      }
      if (formData.plantel3) {
        data.append("plantel3", formData.plantel3);
      }
      await dispatch(FormRegister(data));
      // Envía los datos (descomentar esto una vez que todo esté correcto)

      alert("Escuela registrada con éxito!");
    } catch (error) {
      console.error(
        "Error al registrar la escuela:",
        error.response || error.message
      );
      alert(
        `Hubo un error al registrar la escuela: ${
          error.response?.data?.message || "Intenta nuevamente más tarde."
        }`
      );
    } finally {
      setLoadingSuccess(false);

      setPreviewImage(null);
      setFormData({
        name: "",
        address: "",
        phone: "",
        email: "",
        year_of_operation: "",
        province: "",
        sic: "",
        urlYoutube: "",

        postgrado1: "",
        postgrado2: "",
        beca1: "",
        beca2: "",

        licenciatura1: "",
        licenciatura2: "",
        historia: "",

        alumnos: [],
        alumnos_mixta: [],

        egresados: [],

        doctoresJubilados: [],
        doctoresCandidatos: [],
        profesores: [],
        edad_profesores: [],

        profesoresMaestrias: [],
        profesoresConDoctorados: [],
        plantel1: null,
        plantel2: null,
        plantel3: null,
        image: null,
        profesoresTemporales: [],
        edadesprofesoresTemporales: [],
        matriculaDocentes: [],
        matriculaDocentesEspecialidad: [],

        profesoresTemporalesMaestria: [],
        profesoresTemporalesDoctorado: [],
        cuerposAcademicos: [],
        ofertaAcademicos: [],
        galeria: [],
      });
    }
  };

  const [previewImage, setPreviewImage] = useState(null);
  const [loadingSuccess, setLoadingSuccess] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      image: file,
    });

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDelete = (id) => {
    setFormData((prevData) => ({
      ...prevData,
      galeria: prevData.galeria.filter((item) => item.id !== id), // Filtrar por ID
    }));
  };

  const formatearFechaLatina = (fecha) => {
    const fechaObj = new Date(fecha);
    const dia = String(fechaObj.getDate()).padStart(2, "0");
    const mes = String(fechaObj.getMonth() + 1).padStart(2, "0");
    const anio = fechaObj.getFullYear();
    return `${dia}/${mes}/${anio}`;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddToList = (type, item) => {
    setFormData((prev) => ({
      ...prev,
      [type]: [...prev[type], item],
    }));
  };

  const handleDeleteFromList = (type, index) => {
    setFormData((prev) => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index),
    }));
  };

  const handleAddAlumnos = (e) => {
    e.preventDefault();
    const { fechaDesdeAlumnos, fechaHastaAlumnos, cantidadAlumnos } = formData;
    if (fechaDesdeAlumnos && fechaHastaAlumnos && cantidadAlumnos) {
      handleAddToList("alumnos", {
        fechaDesdeAlumnos,
        fechaHastaAlumnos,
        cantidadAlumnos,
      });
      setFormData((prev) => ({
        ...prev,
        fechaDesdeAlumnos: "",
        fechaHastaAlumnos: "",
        cantidadAlumnos: "",
      }));
    }
  };

  const handleMatriculasDocentes = (e) => {
    e.preventDefault();
    const {
      fechaDesdeMatriculasDocentes,
      fechaHastaMatriculasDocentes,
      cantidadMatriculaDocentes,
    } = formData;
    handleAddToList("matriculaDocentes", {
      fechaDesdeMatriculasDocentes,
      fechaHastaMatriculasDocentes,
      cantidadMatriculaDocentes,
    });
    setFormData((prev) => ({
      ...prev,
      fechaDesdeMatriculasDocentes: "",
      fechaHastaMatriculasDocentes: "",
      cantidadMatriculaDocentes: "",
    }));
  };

  const handleMatriculasDocentesEspecialidad = (e) => {
    e.preventDefault();
    const {
      fechaDesdeMatriculasDocentesEspecialidad,
      fechaHastaMatriculasDocentesEspecialidad,
      cantidadMatriculaDocentesEspecialidad,
    } = formData;
    handleAddToList("matriculaDocentesEspecialidad", {
      fechaDesdeMatriculasDocentesEspecialidad,
      fechaHastaMatriculasDocentesEspecialidad,
      cantidadMatriculaDocentesEspecialidad,
    });
    setFormData((prev) => ({
      ...prev,
      fechaDesdeMatriculasDocentesEspecialidad: "",
      fechaHastaMatriculasDocentesEspecialidad: "",
      cantidadMatriculaDocentesEspecialidad: "",
    }));
  };

  const handleAddAlumnasMixta = (e) => {
    e.preventDefault();
    const {
      fechaDesdeAlumnosMixta,
      fechaHastaAlumnosMixta,
      cantidadAlumnosMixta,
    } = formData;
    handleAddToList("alumnos_mixta", {
      fechaDesdeAlumnosMixta,
      fechaHastaAlumnosMixta,
      cantidadAlumnosMixta,
    });
    setFormData((prev) => ({
      ...prev,
      fechaDesdeAlumnosMixta: "",
      fechaHastaAlumnosMixta: "",
      cantidadAlumnosMixta: "",
    }));
  };

  const handleAddEgresados = (e) => {
    e.preventDefault();
    const {
      fechaDesdeEgresados,
      fechaHastaEgresados,
      cantidadAlumnosEgresados,
    } = formData;
    if (
      fechaDesdeEgresados &&
      fechaHastaEgresados &&
      cantidadAlumnosEgresados
    ) {
      handleAddToList("egresados", {
        fechaDesdeEgresados,
        fechaHastaEgresados,
        cantidadAlumnosEgresados,
      });
      setFormData((prev) => ({
        ...prev,
        fechaDesdeEgresados: "",
        fechaHastaEgresados: "",
        cantidadAlumnosEgresados: "",
      }));
    }
  };

  const handleAddDocotorJubilados = (e) => {
    e.preventDefault();
    const { doctor, nivel } = formData;
    if (doctor) {
      handleAddToList("doctoresJubilados", { doctor, nivel });
      setFormData((prev) => ({ ...prev, doctor: "", nivel: "" }));
    }
  };

  const handleAddDoctorCandidatos = (e) => {
    e.preventDefault();
    const { candidatoDoctor, nivelCandidato } = formData;
    if (candidatoDoctor) {
      handleAddToList("doctoresCandidatos", {
        candidatoDoctor,
        nivelCandidato,
      });
      setFormData((prev) => ({
        ...prev,
        candidatoDoctor: "",
        nivelCandidato: "",
      }));
    }
  };
  const handleAddProfesor = () => {
    const { textProfeactuales } = formData;

    if (textProfeactuales.trim() !== "") {
      setFormData((prev) => ({
        ...prev,
        profesoresTemporales: [...prev.profesoresTemporales, textProfeactuales], // Añade al array temporal
        textProfeactuales: "", // Limpia el input
      }));
    }
  };

  const handleProfesoresedades = (e) => {
    e.preventDefault();
    const { fechaDesdeEdad, fechaHastaEdad, edadesprofesoresTemporales } =
      formData;

    // Añadir un nuevo registro con las fechas y los profesores
    setFormData((prev) => ({
      ...prev,
      edad_profesores: [
        ...prev.edad_profesores,
        {
          fechaDesdeEdad,
          fechaHastaEdad,
          textProfeedades: [...edadesprofesoresTemporales], // Copiar los profesores temporales
        },
      ],
      // Limpiar campos de fecha y la lista de profesores temporales
      edadesprofesoresTemporales: [],
    }));
  };
  const handleAddProfesoresActuales = (e) => {
    e.preventDefault();
    const { fechaDesde, fechaHasta, profesoresTemporales } = formData;

    if (fechaDesde && fechaHasta && profesoresTemporales.length > 0) {
      // Añadir un nuevo registro con las fechas y los profesores
      setFormData((prev) => ({
        ...prev,
        profesores: [
          ...prev.profesores,
          {
            fechaDesde,
            fechaHasta,
            textProfeactuales: [...profesoresTemporales], // Copiar los profesores temporales
          },
        ],
        // Limpiar campos de fecha y la lista de profesores temporales
        fechaDesde: "",
        fechaHasta: "",
        profesoresTemporales: [],
      }));
    }
  };
  const handleAddProfesorEdad = () => {
    const { textProfeedades, edad } = formData;

    if (textProfeedades.trim() !== "" && edad.trim() !== "") {
      setFormData((prev) => ({
        ...prev,
        edadesprofesoresTemporales: [
          ...prev.edadesprofesoresTemporales,
          { nombre: textProfeedades, edad },
        ], // Añade al array temporal
        textProfeedades: "", // Limpia el input
        edad: "",
      }));
    }
  };
  const handleAddProfesorMaestria = () => {
    const { textProfeMaestria, edad_profesoradomaestria } = formData;

    if (
      textProfeMaestria.trim() !== "" &&
      edad_profesoradomaestria.trim() !== ""
    ) {
      setFormData((prev) => ({
        ...prev,
        profesoresTemporalesMaestria: [
          ...prev.profesoresTemporalesMaestria,
          { nombre: textProfeMaestria, edad_profesoradomaestria },
        ], // Añade al array temporal
        textProfeMaestria: "", // Limpia el input
        edad_profesoradomaestria: "",
      }));
    }
  };
  const handleProfesoresMaestria = (e) => {
    e.preventDefault();
    const {
      fechaDesdeMaestria,
      fechaHastaMaestria,
      profesoresTemporalesMaestria,
    } = formData;

    // Añadir un nuevo registro con las fechas y los profesores
    setFormData((prev) => ({
      ...prev,
      profesoresMaestrias: [
        ...prev.profesoresMaestrias,
        {
          fechaDesdeMaestria,
          fechaHastaMaestria,
          textProfeMaestria: [...profesoresTemporalesMaestria], // Copiar los profesores temporales
        },
      ],
      // Limpiar campos de fecha y la lista de profesores temporales
      profesoresTemporalesMaestria: [],
    }));
  };

  const handleAddProfesorDoctorado = () => {
    const { textProfeDoctorado, edad_profesoradodoctorado } = formData;

    if (
      textProfeDoctorado.trim() !== "" &&
      edad_profesoradodoctorado.trim() !== ""
    ) {
      setFormData((prev) => ({
        ...prev,
        profesoresTemporalesDoctorado: [
          ...prev.profesoresTemporalesDoctorado,
          { nombre: textProfeDoctorado, edad_profesoradodoctorado },
        ], // Añade al array temporal
        textProfeDoctorado: "", // Limpia el input
        edad_profesoradodoctorado: "",
      }));
    }
  };
  const handleProfesoresDoctorado = (e) => {
    e.preventDefault();
    const {
      fechaDesdeDoctorado,
      fechaHastaDoctorado,
      profesoresTemporalesDoctorado,
    } = formData;

    // Añadir un nuevo registro con las fechas y los profesores
    setFormData((prev) => ({
      ...prev,
      profesoresConDoctorados: [
        ...prev.profesoresConDoctorados,
        {
          fechaDesdeDoctorado,
          fechaHastaDoctorado,
          textProfeDoctorado: [...profesoresTemporalesDoctorado], // Copiar los profesores temporales
        },
      ],
      // Limpiar campos de fecha y la lista de profesores temporales
      profesoresTemporalesDoctorado: [],
    }));
  };

  const handleCuerpoAcademico = (e) => {
    e.preventDefault();
    const { periodo, descripcion, estado } = formData;
    if (periodo && descripcion && estado) {
      handleAddToList("cuerposAcademicos", { periodo, descripcion, estado });
      setFormData((prev) => ({
        ...prev,
        periodo: "",
        descripcion: "",
        estado: "",
      }));
    }
  };

  const handleOfertaAcademico = (e) => {
    e.preventDefault();
    const { oferta } = formData;
    if (oferta) {
      handleAddToList("ofertaAcademicos", { oferta });
      setFormData((prev) => ({ ...prev, oferta: "" }));
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      if (place?.geometry) {
        const addressComponents = place.address_components;
        if (addressComponents) {
          setFormData({
            ...formData,
            address: place.formatted_address,
          });
        } else {
          console.error("No se pudo obtener los componentes de la dirección.");
        }
      } else {
        console.error("No se pudo obtener la información de geometría.");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="responsive-form">
      <div className="form-group">
        <label>Nombre de la escuela:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label>Dirección:</label>
        <LoadScript
          googleMapsApiKey="AIzaSyBMqv1fgtsDEQQgm4kmLBRtZI7zu-wSldA" // Reemplaza con tu clave API
          libraries={["places"]} // Necesario para usar Autocomplete
        >
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </Autocomplete>
        </LoadScript>
      </div>

      <div className="form-group">
        <label>Teléfono:</label>
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          placeholder="Telefóno" // Placeholder para indicar el formato esperado
        />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input
          type="text"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Correo electrónico" // Placeholder para indicar el formato esperado
        />
      </div>
      <div className="form-group">
        <label>Año de fundación:</label>
        <input
          type="date" // Cambiado a tipo "text" para permitir el formato
          name="year_of_operation"
          value={formData.year_of_operation}
          onChange={handleInputChange}
          placeholder="dd/mm/aaaa" // Placeholder para indicar el formato esperado
        />
      </div>
      <div className="form-group">
        <label>Provincia:</label>
        <select
          name="province"
          value={formData.province}
          onChange={handleInputChange}
          required
        >
          <option value="">Seleccionar Provincia</option>
          <option value="Aguascalientes">Aguascalientes</option>
          <option value="Baja California">Baja California</option>
          <option value="Baja California Sur">Baja California Sur</option>
          <option value="Campeche">Campeche</option>
          <option value="Chiapas">Chiapas</option>
          <option value="Chihuahua">Chihuahua</option>
          <option value="Coahuila de Zaragoza">Coahuila de Zaragoza</option>
          <option value="Colima">Colima</option>
          <option value="Durango">Durango</option>
          <option value="Guanajuato">Guanajuato</option>
          <option value="Guerrero">Guerrero</option>
          <option value="Hidalgo">Hidalgo</option>
          <option value="Jalisco">Jalisco</option>
          <option value="Estado de México">Estado de México</option>
          <option value="Michoacán de Ocampo">Michoacán de Ocampo</option>
          <option value="Morelos">Morelos</option>
          <option value="Nayarit">Nayarit</option>
          <option value="Nuevo León">Nuevo León</option>
          <option value="Oaxaca">Oaxaca</option>
          <option value="Puebla">Puebla</option>
          <option value="Querétaro">Querétaro</option>
          <option value="Quintana Roo">Quintana Roo</option>
          <option value="San Luis Potosí">San Luis Potosí</option>
          <option value="Sinaloa">Sinaloa</option>
          <option value="Sonora">Sonora</option>
          <option value="Tabasco">Tabasco</option>
          <option value="Tamaulipas">Tamaulipas</option>
          <option value="Tlaxcala">Tlaxcala</option>
          <option value="Veracruz de Ignacio de la Llave">
            Veracruz de Ignacio de la Llave
          </option>
          <option value="Yucatán">Yucatán</option>
          <option value="Zacatecas">Zacatecas</option>
        </select>
      </div>
      <div className="form-group">
        <label>SIC:</label>
        <input
          type="text" // Cambiado a tipo "text" para permitir el formato
          name="sic"
          value={formData.sic}
          onChange={handleInputChange}
          placeholder="sic" // Placeholder para indicar el formato esperado
        />
      </div>

      <div className="form-group">
        <label>Enlace de youtube:</label>
        <input
          type="text" // Cambiado a tipo "text" para permitir el formato
          name="urlYoutube"
          value={formData.urlYoutube}
          onChange={handleInputChange}
          placeholder="Enlace de youtube" // Placeholder para indicar el formato esperado
        />
      </div>
      <div className="form-group">
        <label>Enlace del primer postgrado:</label>
        <input
          type="text" // Cambiado a tipo "text" para permitir el formato
          name="postgrado1"
          value={formData.postgrado1}
          onChange={handleInputChange}
          placeholder="enlace del postgrado" // Placeholder para indicar el formato esperado
        />
      </div>
      <div className="form-group">
        <label>Enlace del segundo postgrado:</label>
        <input
          type="text" // Cambiado a tipo "text" para permitir el formato
          name="postgrado2"
          value={formData.postgrado2}
          onChange={handleInputChange}
          placeholder="enlace del postgrado" // Placeholder para indicar el formato esperado
        />
      </div>

      <div className="form-group">
        <label>Enlace de la primera beca:</label>
        <input
          type="text" // Cambiado a tipo "text" para permitir el formato
          name="beca1"
          value={formData.beca1}
          onChange={handleInputChange}
          placeholder="enlace del postgrado" // Placeholder para indicar el formato esperado
        />
      </div>

      <div className="form-group">
        <label>Enlace de la segunda beca:</label>
        <input
          type="text" // Cambiado a tipo "text" para permitir el formato
          name="beca2"
          value={formData.beca2}
          onChange={handleInputChange}
          placeholder="enlace del postgrado" // Placeholder para indicar el formato esperado
        />
      </div>

      <div className="form-group">
        <label> Enlace de la primera Licencaturas:</label>
        <input
          type="text" // Cambiado a tipo "text" para permitir el formato
          name="licenciatura1"
          value={formData.licenciatura1}
          onChange={handleInputChange}
          placeholder="enlace de la licencaturas" // Placeholder para indicar el formato esperado
        />
      </div>

      <div className="form-group">
        <label> Enlace de la segunda Licencaturas:</label>
        <input
          type="text" // Cambiado a tipo "text" para permitir el formato
          name="licenciatura2"
          value={formData.licenciatura2}
          onChange={handleInputChange}
          placeholder="enlace de la licencaturas" // Placeholder para indicar el formato esperado
        />
      </div>

      <div className="form-group">
        <label>Historia</label>
        <textarea
          type="text" // Cambiado a tipo "text" para permitir el formato
          name="historia"
          value={formData.historia}
          onChange={handleInputChange}
          placeholder="Historia" // Placeholder para indicar el formato esperado
        />
      </div>
      <h2>Alumnos matriculados: Modalidad escolarizada.</h2>
      <input
        type="text"
        name="fechaDesdeAlumnos"
        value={formData.fechaDesdeAlumnos}
        onChange={handleInputChange}
        placeholder="Fecha Desde" // Placeholder para indicar el formato esperado
      />
      <input
        type="text"
        name="fechaHastaAlumnos"
        value={formData.fechaHastaAlumnos}
        onChange={handleInputChange}
        placeholder="Fecha Hasta" // Placeholder para indicar el formato esperado
      />
      <input
        type="number"
        name="cantidadAlumnos"
        value={formData.cantidadAlumnos}
        onChange={handleInputChange}
        placeholder="Cantidad" // Placeholder para indicar el formato esperado
      />
      <button onClick={handleAddAlumnos}>Agregar</button>
      <ul className="record-list">
        {formData.alumnos &&
          formData.alumnos.map((registro, index) => (
            <li key={index}>
              <span>{`Desde: ${registro.fechaDesdeAlumnos}, Hasta: ${registro.fechaHastaAlumnos}, Cantidad: ${registro.cantidadAlumnos}`}</span>
              <button onClick={() => handleDeleteFromList("alumnos", index)}>
                Eliminar
              </button>
            </li>
          ))}
      </ul>

      <h2>Alumnos matriculados: Modalidad Mixta.</h2>
      <input
        type="text"
        name="fechaDesdeAlumnosMixta"
        value={formData.fechaDesdeAlumnosMixta}
        onChange={handleInputChange}
        placeholder="Fecha Desde" // Placeholder para indicar el formato esperado
      />
      <input
        type="text"
        name="fechaHastaAlumnosMixta"
        value={formData.fechaHastaAlumnosMixta}
        onChange={handleInputChange}
        placeholder="Fecha Hasta" // Placeholder para indicar el formato esperado
      />
      <input
        type="number"
        name="cantidadAlumnosMixta"
        value={formData.cantidadAlumnosMixta}
        onChange={handleInputChange}
        placeholder="Cantidad" // Placeholder para indicar el formato esperado
      />
      <button onClick={handleAddAlumnasMixta}>Agregar</button>
      <ul className="record-list">
        {formData.alumnos_mixta &&
          formData.alumnos_mixta.map((registro, index) => (
            <li key={index}>
              <span>{`Desde: ${registro.fechaDesdeAlumnosMixta}, Hasta: ${registro.fechaHastaAlumnosMixta}, Cantidad: ${registro.cantidadAlumnosMixta}`}</span>
              <button
                onClick={() => handleDeleteFromList("alumnos_mixta", index)}
              >
                Eliminar
              </button>
            </li>
          ))}
      </ul>

      <h2> Alumnos Egresados y titulados: Modalidad escolarizada.</h2>
      <input
        type="text"
        name="fechaDesdeEgresados"
        value={formData.fechaDesdeEgresados}
        onChange={handleInputChange}
        placeholder="Fecha Desde" // Placeholder para indicar el formato esperado
      />
      <input
        type="text"
        name="fechaHastaEgresados"
        value={formData.fechaHastaEgresados}
        onChange={handleInputChange}
        placeholder="Fecha Hasta" // Placeholder para indicar el formato esperado
      />
      <input
        type="number"
        name="cantidadAlumnosEgresados"
        value={formData.cantidadAlumnosEgresados}
        onChange={handleInputChange}
        placeholder="Cantidad" // Placeholder para indicar el formato esperado
      />
      <button onClick={handleAddEgresados}>Agregar</button>
      <ul className="record-list">
        {formData.egresados.map((registro, index) => (
          <li key={index}>
            <span>{`Desde: ${registro.fechaDesdeEgresados}, Hasta: ${registro.fechaHastaEgresados}, Cantidad: ${registro.cantidadAlumnosEgresados}`}</span>
            <button onClick={() => handleDeleteFromList("egresados", index)}>
              Eliminar
            </button>
          </li>
        ))}
      </ul>

      <h2>Lista de profesores actual</h2>

      {/* Input de fechas */}
      <input
        type="text"
        name="fechaDesde"
        value={formData.fechaDesde}
        onChange={handleInputChange}
        placeholder="Fecha Desde"
      />
      <input
        type="text"
        name="fechaHasta"
        value={formData.fechaHasta}
        onChange={handleInputChange}
        placeholder="Fecha Hasta"
      />

      {/* Campo para agregar un profesor */}
      <input
        type="text"
        name="textProfeactuales"
        value={formData.textProfeactuales}
        onChange={handleInputChange}
        placeholder="Nombre del Profesor"
      />

      {/* Botón para agregar profesor temporalmente */}
      <button type="button" onClick={handleAddProfesor}>
        Agregar Profesor
      </button>

      {/* Lista temporal de profesores */}
      <ul>
        {formData.profesoresTemporales &&
          formData.profesoresTemporales.map((profe, i) => (
            <li key={i}>{`Profesor: ${profe}`}</li>
          ))}
      </ul>

      {/* Botón para agregar el registro completo */}
      <button onClick={handleAddProfesoresActuales}>Agregar Registro</button>

      {/* Lista de registros finales */}
      <ul className="record-list">
        {formData.profesores.map((registro, index) => (
          <li key={index}>
            <span>{`Desde: ${registro.fechaDesde}, Hasta: ${
              registro.fechaHasta ? registro.fechaHasta : ""
            }, Profesores: ${registro.textProfeactuales.map(
              (profe, i) => `Profesor: ${profe}`
            )}`}</span>

            <button onClick={() => handleDeleteFromList("profesores", index)}>
              Eliminar
            </button>
          </li>
        ))}
      </ul>

      <h2>Edades del profesorado</h2>

      {/* Input de fechas */}
      <input
        type="text"
        name="fechaDesdeEdad"
        value={formData.fechaDesdeEdad}
        onChange={handleInputChange}
        placeholder="Fecha Desde"
      />
      <input
        type="text"
        name="fechaHastaEdad"
        value={formData.fechaHastaEdad}
        onChange={handleInputChange}
        placeholder="Fecha Hasta"
      />

      {/* Input para el nombre del profesor */}
      <input
        type="text"
        name="textProfeedades"
        value={formData.textProfeedades}
        onChange={handleInputChange}
        placeholder="Nombre del Profesor"
      />

      {/* Input para la edad del profesor */}
      <input
        type="number"
        name="edad"
        value={formData.edad}
        onChange={handleInputChange}
        placeholder="Edad del profesor"
      />

      {/* Botón para agregar profesor temporalmente */}
      <button type="button" onClick={handleAddProfesorEdad}>
        Agregar Profesor
      </button>

      {/* Lista temporal de profesores */}
      <ul>
        {formData.edadesprofesoresTemporales.map((profe, i) => (
          <li key={i}>{`Profesor: ${profe.nombre}, Edad: ${profe.edad}  `}</li>
        ))}
      </ul>

      {/* Botón para agregar el registro completo */}
      <button onClick={handleProfesoresedades}>Agregar Registro</button>

      {/* Lista de registros finales */}
      <ul className="record-list">
        {formData.edad_profesores.map((registro, index) => (
          <li key={index}>
            <span>{`Desde: ${registro.fechaDesdeEdad}, Hasta: ${
              registro.fechaHastaEdad ? registro.fechaHastaEdad : ""
            }, ${registro.textProfeedades.map(
              (profe, i) =>
                `Profesor: ${profe.nombre},  Edad: ${profe.edad} ${" "}`
            )}`}</span>

            <button
              onClick={() => handleDeleteFromList("edad_profesores", index)}
            >
              Eliminar
            </button>
          </li>
        ))}
      </ul>

      <h2>Lista de profesores con maestrias</h2>

      {/* Input de fechas */}
      <input
        type="text"
        name="fechaDesdeMaestria"
        value={formData.fechaDesdeMaestria}
        onChange={handleInputChange}
        placeholder="Fecha Desde"
      />
      <input
        type="text"
        name="fechaHastaMaestria"
        value={formData.fechaHastaMaestria}
        onChange={handleInputChange}
        placeholder="Fecha Hasta"
      />

      {/* Campo para agregar un profesor */}
      <input
        type="text"
        name="textProfeMaestria"
        value={formData.textProfeMaestria}
        onChange={handleInputChange}
        placeholder="Nombre del Profesor"
      />

      <input
        type="number"
        name="edad_profesoradomaestria"
        value={formData.edad_profesoradomaestria}
        onChange={handleInputChange}
        placeholder="Edad"
      />

      {/* Botón para agregar profesor temporalmente */}
      <button type="button" onClick={handleAddProfesorMaestria}>
        Agregar Profesor
      </button>

      {/* Lista temporal de profesores */}
      <ul>
        {formData.profesoresTemporalesMaestria &&
          formData.profesoresTemporalesMaestria.map((profe, i) => (
            <li
              key={i}
            >{`Profesor: ${profe.nombre}, edad: ${profe.edad_profesoradomaestria}`}</li>
          ))}
      </ul>

      {/* Botón para agregar el registro completo */}
      <button onClick={handleProfesoresMaestria}>Agregar Registro</button>

      {/* Lista de registros finales */}

      <ul className="record-list">
        {formData.profesoresMaestrias.map((registro, index) => (
          <li key={index}>
            <span>{`Desde: ${registro.fechaDesdeMaestria}, Hasta: ${
              registro.fechaHastaMaestria ? registro.fechaHastaMaestria : ""
            }, ${registro.textProfeMaestria.map(
              (profe, i) =>
                `Profesor: ${profe.nombre},  Edad: ${
                  profe.edad_profesoradomaestria
                } ${" "}`
            )}`}</span>

            <button
              onClick={() => handleDeleteFromList("profesoresMaestrias", index)}
            >
              Eliminar
            </button>
          </li>
        ))}
      </ul>

      <h2>Lista de profesores con doctorados</h2>

      {/* Input de fechas */}
      <input
        type="text"
        name="fechaDesdeDoctorado"
        value={formData.fechaDesdeDoctorado}
        onChange={handleInputChange}
        placeholder="Fecha Desde"
      />
      <input
        type="text"
        name="fechaHastaDoctorado"
        value={formData.fechaHastaDoctorado}
        onChange={handleInputChange}
        placeholder="Fecha Hasta"
      />

      {/* Campo para agregar un profesor */}
      <input
        type="text"
        name="textProfeDoctorado"
        value={formData.textProfeDoctorado}
        onChange={handleInputChange}
        placeholder="Nombre del Profesor"
      />
      <input
        type="number"
        name="edad_profesoradodoctorado"
        value={formData.edad_profesoradodoctorado}
        onChange={handleInputChange}
        placeholder="Edad"
      />
      {/* Botón para agregar profesor temporalmente */}
      <button type="button" onClick={handleAddProfesorDoctorado}>
        Agregar Profesor
      </button>

      {/* Lista temporal de profesores */}
      <ul>
        {formData.profesoresTemporalesDoctorado &&
          formData.profesoresTemporalesDoctorado.map((profe, i) => (
            <li
              key={i}
            >{`Profesor: ${profe.nombre}, edad: ${profe.edad_profesoradodoctorado}`}</li>
          ))}
      </ul>

      {/* Botón para agregar el registro completo */}
      <button onClick={handleProfesoresDoctorado}>Agregar Registro</button>

      {/* Lista de registros finales */}
      <ul className="record-list">
        {formData.profesoresConDoctorados.map((registro, index) => (
          <li key={index}>
            <span>{`Desde: ${registro.fechaDesdeDoctorado}, Hasta: ${
              registro.fechaHastaDoctorado ? registro.fechaHastaDoctorado : ""
            },${registro.textProfeDoctorado.map(
              (profe, i) =>
                `Profesor: ${profe.nombre},  Edad: ${
                  profe.edad_profesoradodoctorado
                } ${" "}`
            )}`}</span>

            <button
              onClick={() =>
                handleDeleteFromList("profesoresConDoctorados", index)
              }
            >
              Eliminar
            </button>
          </li>
        ))}
      </ul>

      <h2>Matricula docente</h2>
      <input
        type="text"
        name="fechaDesdeMatriculasDocentes"
        value={formData.fechaDesdeMatriculasDocentes}
        onChange={handleInputChange}
        placeholder="Fecha Desde" // Placeholder para indicar el formato esperado
      />
      <input
        type="text"
        name="fechaHastaMatriculasDocentes"
        value={formData.fechaHastaMatriculasDocentes}
        onChange={handleInputChange}
        placeholder="Fecha Hasta" // Placeholder para indicar el formato esperado
      />
      <input
        type="number"
        name="cantidadMatriculaDocentes"
        value={formData.cantidadMatriculaDocentes}
        onChange={handleInputChange}
        placeholder="Cantidad" // Placeholder para indicar el formato esperado
      />
      <button onClick={handleMatriculasDocentes}>Agregar</button>
      <ul className="record-list">
        {formData.matriculaDocentes &&
          formData.matriculaDocentes.map((registro, index) => (
            <li key={index}>
              <span>{`Desde: ${
                registro.fechaDesdeMatriculasDocentes
                  ? registro.fechaDesdeMatriculasDocentes
                  : ""
              }, Hasta: ${
                registro.fechaHastaMatriculasDocentes
                  ? registro.fechaHastaMatriculasDocentes
                  : "-"
              }, Cantidad: ${registro.cantidadMatriculaDocentes}`}</span>
              <button
                onClick={() => handleDeleteFromList("matriculaDocentes", index)}
              >
                Eliminar
              </button>
            </li>
          ))}
      </ul>

      <h2>Matricula docente con especialidad</h2>
      <input
        type="text"
        name="fechaDesdeMatriculasDocentesEspecialidad"
        value={formData.fechaDesdeMatriculasDocentesEspecialidad}
        onChange={handleInputChange}
        placeholder="Fecha Desde" // Placeholder para indicar el formato esperado
      />
      <input
        type="text"
        name="fechaHastaMatriculasDocentesEspecialidad"
        value={formData.fechaHastaMatriculasDocentesEspecialidad}
        onChange={handleInputChange}
        placeholder="Fecha Hasta" // Placeholder para indicar el formato esperado
      />
      <input
        type="number"
        name="cantidadMatriculaDocentesEspecialidad"
        value={formData.cantidadMatriculaDocentesEspecialidad}
        onChange={handleInputChange}
        placeholder="Cantidad" // Placeholder para indicar el formato esperado
      />
      <button onClick={handleMatriculasDocentesEspecialidad}>Agregar</button>
      <ul className="record-list">
        {formData.matriculaDocentesEspecialidad &&
          formData.matriculaDocentesEspecialidad.map((registro, index) => (
            <li key={index}>
              <span>{`Desde: ${
                registro.fechaDesdeMatriculasDocentesEspecialidad
              }, Hasta: ${
                registro.fechaHastaMatriculasDocentesEspecialidad
                  ? registro.fechaHastaMatriculasDocentesEspecialidad
                  : ""
              }, Cantidad: ${
                registro.cantidadMatriculaDocentesEspecialidad
              }`}</span>
              <button
                onClick={() =>
                  handleDeleteFromList("matriculaDocentesEspecialidad", index)
                }
              >
                Eliminar
              </button>
            </li>
          ))}
      </ul>

      <h2>Doctores jubilados</h2>
      <input
        type="text"
        name="doctor"
        value={formData.doctor}
        onChange={handleInputChange}
        placeholder="Nombre"
      />

      <input
        type="text"
        name="nivel"
        value={formData.nivel}
        onChange={handleInputChange}
        placeholder="Nivel"
      />
      <button onClick={handleAddDocotorJubilados}>Agregar</button>
      <ul className="record-list">
        {formData.doctoresJubilados.map((registro, index) => (
          <li key={index}>
            <span>{` ${registro.doctor},  ${registro.nivel}`}</span>
            <button
              onClick={() => handleDeleteFromList("doctoresJubilados", index)}
            >
              Eliminar
            </button>
          </li>
        ))}
      </ul>

      <h2>Doctores candidatos</h2>
      <input
        type="data"
        name="candidatoDoctor"
        value={formData.candidatoDoctor}
        onChange={handleInputChange}
        placeholder="Nombre"
      />

      <input
        type="data"
        name="nivelCandidato"
        value={formData.nivelCandidato}
        onChange={handleInputChange}
        placeholder="Nivel"
      />
      <button onClick={handleAddDoctorCandidatos}>Agregar</button>
      <ul className="record-list">
        {formData.doctoresCandidatos.map((registro, index) => (
          <li key={index}>
            <span>{` ${registro.candidatoDoctor}, ${registro.nivelCandidato}`}</span>
            <button
              onClick={() => handleDeleteFromList("doctoresCandidatos", index)}
            >
              Eliminar
            </button>
          </li>
        ))}
      </ul>
      {/* Repite la misma estructura para egresados, doctores y profesores */}

      <h2>Cuerpos académicos</h2>
      <input
        type="text"
        name="periodo"
        value={formData.periodo}
        onChange={handleInputChange}
        placeholder="Periodo" // Placeholder para indicar el formato esperado
      />
      <input
        type="text"
        name="descripcion"
        value={formData.descripcion}
        onChange={handleInputChange}
        placeholder="Descripción" // Placeholder para indicar el formato esperado
      />
      <input
        type="text"
        name="estado"
        value={formData.estado}
        onChange={handleInputChange}
        placeholder="Estado" // Placeholder para indicar el formato esperado
      />
      <button onClick={handleCuerpoAcademico}>Agregar</button>
      <ul className="record-list">
        {formData.cuerposAcademicos &&
          formData.cuerposAcademicos.map((registro, index) => (
            <li key={index}>
              <span>{`Periodo: ${registro.periodo}, Descripción: ${registro.descripcion}, Estado: ${registro.estado}`}</span>
              <button
                onClick={() => handleDeleteFromList("cuerposAcademicos", index)}
              >
                Eliminar
              </button>
            </li>
          ))}
      </ul>

      <h2>Oferta académicos</h2>
      <input
        type="text"
        name="oferta"
        value={formData.oferta}
        onChange={handleInputChange}
        placeholder="Oferta" // Placeholder para indicar el formato esperado
      />

      <button onClick={handleOfertaAcademico}>Agregar</button>
      <ul className="record-list">
        {formData.ofertaAcademicos &&
          formData.ofertaAcademicos.map((registro, index) => (
            <li key={index}>
              <span>{`Oferta: ${registro.oferta}`}</span>
              <button
                onClick={() => handleDeleteFromList("ofertaAcademicos", index)}
              >
                Eliminar
              </button>
            </li>
          ))}
      </ul>

      <div>
        <div>
          <h2>Subir Imagen de la galeria</h2>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChangeGallery}
          />
        </div>

        <div className="carruselData">
          {formData.galeria.map((data) => (
            <div key={data} className="imgContainer">
              <img
                src={data.foto}
                alt={`Imagen ${data.id}`}
                style={{ width: "150px", margin: "10px" }}
              />
              <div onClick={() => handleDelete(data.id)} className="btnGaleria">
                Eliminar
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <label>PDF 1:</label>
        <input
          type="file"
          name="plantel1"
          accept=".pdf"
          onChange={handleFileChange}
        />
      </div>

      <div>
        <label>PDF 2:</label>
        <input
          type="file"
          name="plantel2"
          accept=".pdf"
          onChange={handleFileChange}
        />
      </div>

      <div>
        <label>PDF 3:</label>
        <input
          type="file"
          name="plantel3"
          accept=".pdf"
          onChange={handleFileChange}
        />
      </div>
      <div className="form-group">
        <label>Subir imagen:</label>
        <input type="file" accept="image/*" onChange={handleImageChange} />
      </div>

      {previewImage && (
        <div className="preview-image">
          <p>Vista previa de la imagen:</p>
          <img src={previewImage} alt="Vista previa" />
        </div>
      )}
      <button type="submit">
        {loadingSuccess ? (
          <CircularProgress size={25} thickness={5} sx={{ color: "#fff" }} />
        ) : (
          "Registrarse"
        )}
      </button>
    </form>
  );
};

export default RegisterSchool;
